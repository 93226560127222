import React from "react";
import store from "./store";
import {Provider} from "react-redux";

export default (component) => {
    return (
        <Provider store={store}>
            {component}
        </Provider>
    );
};