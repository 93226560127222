import React, {Component,} from 'react';
import './Styles/MainContent.css';
import {isMobile} from "./Stateless/HelperFunctions";
// import ReactDOM from "react-dom";
// import Async from "react-async";
// import TEST from "./TEST";

class MainContent extends Component {

    resized = () => {
        let mob = isMobile();
        this.setState({
            isMobile: mob,
        });
        return mob;
    };

    constructor(props) {
        super(props);
        this.state = {
            currentPage: props.currentPage,
            data: [],
            isMobile: false,
        };
    }

    componentDidMount() {
        this.resized();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => this.resized());
    }

    componentWillMount() {
        window.addEventListener("resize", () => this.resized());
    }

    render() {
        return (
            <main id={"main"}>
                <main id={"mainContent"}>
                    <div className={`${this.state.isMobile ? "" : "height-spacer medium"}`}/>
                    <div className={"current wrapper"}>
                        <article>
                            <h4 className={"subject"}>Aktuelle Predigtreihe</h4>
                            <h1 className={"title"}>Johannes</h1>
                            <p>Lorem ipsum is a pseudo-Latin text used in web design, typography, layout, and printing in place of English to emphasise design elements over
                                content.
                                It's also called placeholder (or filler) text. It's a convenient tool for mock-ups. It helps to outline the visual elements of a document or
                                presentation, eg typography, font, or layout. Lorem ipsum is mostly a part of a Latin text by the classical author and philosopher Cicero. Its words
                                and
                                letters have been changed by addition or removal, so to deliberately render its content nonsensical; it's not genuine, correct, or comprehensible
                                Latin
                                anymore.</p>
                            <div className={"divider left"}/>
                            <div className={"last-sermon"}>
                                <p>
                                    <span className={"last"}>Letzte Predigt:</span> <span className={"last-title"}>Was jetzt?</span>
                                </p>
                            </div>
                        </article>
                    </div>
                    <div className={`${this.state.isMobile ? "" : "height-spacer medium"}`}/>
                </main>
            </main>
        );
    }
}

export default MainContent;
