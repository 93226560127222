import React, {Component} from 'react';
import './Styles/Header.css';
import SocialMediaIcons from "./Stateless/SocialMediaIcons";
import CurrentEvent from "./Stateless/CurrentEvent";
import CenterLogo from "./Stateless/CenterLogo";
import BurgerMenu from "./Mobile/BurgerMenu";
import {isMobile} from "./Stateless/HelperFunctions";

const threshold = 50;

class Header extends Component {

    toggleShadow = () => {
        let state = window.scrollY >= threshold;
        this.setState({
            headerShadow: this.getShadowState(state),
        });
    };

    getShadowState = state => {
        return state ? " softShadow " : "";
    };

    resized = () => {
        let mob = isMobile();
        this.setState({
            isMobile: mob,
        });
        return mob;
    };

    rightComponent = () => {
        if (this.state.isMobile) {
            return <BurgerMenu/>
        }
        return <CurrentEvent days={2} post={"bis zum"} event={"Fire-Up"}/>
    };

    constructor(props) {
        super(props);

        this.state = {
            headerShadow: "",
            isMobile: false,
        };
    }

    componentDidMount() {
        this.resized();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => this.resized());
        document.removeEventListener("scroll", () => this.toggleShadow());
    }

    componentWillMount() {
        window.addEventListener("resize", () => this.resized());
        document.addEventListener("scroll", () => this.toggleShadow());
    }

    render() {
        return (
            <header id={"header"}>
                <div ref={this.headerRef} className={"wrapper noShadow trans long delay " + this.state.headerShadow}>
                    <SocialMediaIcons/>
                    <CenterLogo/>
                    {
                        this.rightComponent()
                    }
                </div>
            </header>
        );
    }
}

export default Header;
