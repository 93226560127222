import React from 'react';
import {ScaleLoader} from 'react-spinners';
import './Styles/Loader.css';
import Colors from "../../asdf/Colors";

const Loader = props => {
    return (
        <div className="loader wrapper">
            <ScaleLoader
                sizeUnit={"px"}
                size={150}
                height={30} width={5}
                radius={5}
                margin={"2px"}
                color={Colors.accent}
                loading={props.loading}
            />
        </div>
    )
};

export default Loader;
