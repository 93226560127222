import React, {Component} from 'react';
import './Styles/BurgerMenu.css';
import SVG from "../Stateless/SVGcomponent";
import Icons from "../../Assets/Icons";
import BurgerMenuContainer from "./BurgerMenuContainerMobile";

class BurgerMenu extends Component {

    toggle = () => {
        this.setState({
            open: !this.state.open
        })
    };
    menuWrapper = () => {
        if (!this.state.open) {
            return (null)
        }

        return <BurgerMenuContainer/>
    };

    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }
    }

    render() {
        return (
            <div onClick={() => this.toggle()} id={"mobile-menu"} className={"wrapper sides"}>
                <div className={`wrapper burger-toggle trans ${this.state.open ? "open" : ""}`}>
                    <div className={"wrapper icon"}>{
                        SVG(Icons.navigation.menu)
                    }</div>
                </div>
                <div id={"mobile-menu-wrapper"} className={`wrapper trans ${this.state.open ? "active" : ""}`}>
                    {
                        this.menuWrapper()
                    }
                </div>
            </div>
        )
    }

}

export default BurgerMenu;
