import React from 'react';
import Icons from "../../Assets/Icons";
import {sortByIndex} from "./HelperFunctions";
import SVG from "./SVGcomponent";

const SocialMediaIcons = () => {
    return (
        <div id={"social-icons"} className={"wrapper sides"}>{
            sortByIndex(Icons.socialmedia)
                .map((icon) => <a key={icon.id} className={"icon wrapper"} target={"_blank"}
                                  href={icon.href}>{SVG(icon)}</a>)
        }</div>
    );
};

export default SocialMediaIcons;