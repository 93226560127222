import React, {Component} from "react";
import EntrySlideshow from "./EntrySlideshow";
import NavigationItem from "./Stateless/NavigationItem";
import './Styles/HomePageNavigation.css';
import articleLinks from "../Routing/urls/ArticleLinks";
import {isMobile} from "./Stateless/HelperFunctions";

class HomePageNavigation extends Component {

    toggleArticle = state => {
        if (state) {
            document.body.classList.add('noScroll');
        } else {
            document.body.classList.remove('noScroll');
        }
    };

    resized = () => {
        let mob = isMobile();
        this.setState({
            isMobile: mob,
        });
        return mob;
    };

    constructor(props) {
        super(props);
        this.state = {
            router: {},
            links: articleLinks,
            isMobile: false
        };
    }

    componentDidMount() {
        this.resized();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => this.resized());
    }

    componentWillMount() {
        window.addEventListener("resize", () => this.resized());
    }

    getItem() {
        // if(!this.state.articleLinks) return [];
        let classNames = "nav-item";
        // let activeClassName = "active";
        let items = [];


        for (let i = 0; i < articleLinks.length; i++) {
            let item = articleLinks[i];

            item.classNames = classNames;
            item.context = this;
            items.push(item);
        }

        return items;
    }

    renderNavigation() {
        if (isMobile()) {
            return (
                <div className={"height-spacer medium"}/>
            )
        }

        return (
            <nav id={"homepageNav"}>
                <div className={"navList wrapper "}>
                    <ul>{
                        this.getItem().map(item => NavigationItem(item, {key: item.index}))
                    }</ul>
                </div>
            </nav>
        )
    }

    render() {
        return (
            <div className={"homepageNav wrapper"}>
                <div className={"height-spacer medium-1"}/>
                <h1 className={"welcome"}>Willkommen</h1>
                {
                    this.renderNavigation()
                }
                <EntrySlideshow/>
            </div>
        );
    }
}

export default HomePageNavigation;