import React from "react";
import {Link} from "react-router-dom";
// import {initLinks} from "../../redux/reduxActions";
import "./Styles/NavigationItemMobile.css";

const NavigationItemMobile = prop => {
    const {link_title, title, classNames, index, href, pre_title} = prop;
    const t = pre_title[0] ? `${pre_title[1]}-` : "";
    return (
        <li key={`${index}:{${t}${title}}`} className={`mobile ${classNames}`}>
            <div className={"wrapper long trans"}>
                <Link to={`/${href}`}>
                    <div className={"link wrapper trans"}>
                        <span>{link_title}</span>
                    </div>
                </Link>
            </div>
        </li>
    )
};

export default NavigationItemMobile;