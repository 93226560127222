import Logos from "../../Assets/Logos";
import React from "react";
import CustomImage from "./CustomImage";

const CenterLogo = () => {
    return (
        <div id={"logo"} className={"wrapper"}>
            <CustomImage imgs={Logos.impact}/>
        </div>
    )
};

export default CenterLogo;