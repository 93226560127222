import React from 'react';
import Image from "react-image";
import UnLoader from "./UnLoader";
import Loader from "./Loader";
import VisibilitySensor from 'react-visibility-sensor';

const CustomImage = props => {

    const imgs = props.imgs;
    const loader = () => {
        if (props.loader) {
            return props.loader;
        } else {
            return (<Loader/>)
        }
    };
    const alternative = () => {
        if (props.alt) {
            return props.alt;
        } else {
            return (<UnLoader/>)
        }
    };

    return (
        <VisibilitySensor>
            <Image
                src={imgs}
                loader={loader()}
                unloader={alternative()}
            />
        </VisibilitySensor>
    );
};

export default CustomImage;
