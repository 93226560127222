import {GET, GET_MAPPED, INIT, SET, SET_ACTIVE} from "../reduxActions/MainActions";

const initialState = {
    // navigation
    links: [],

    // Routing
    router: {
        components: [],
        routes: [],
    },

    isMobile: false,

    // content
    pages: {},
    images: {},
    articles: {},

    cookie: null,

    components: {
        header: (null),
    },

    // HomePage
    currentPage: undefined,
    visible: false,
    imgs: {
        slideshow: [],
    },
};

const MainReducer = (state = initialState, payload) => {
    switch (payload.type) {
        case INIT:
            return {
                key: payload.value,
                links: payload.links,
                activeLink: payload.activeLink,
            };
        case SET:
            state[payload.key] = payload.value;
            return state[payload.key];
        case GET:
            if ("key" in payload) {
                return state[payload["key"]];
            }
            return state;
        case GET_MAPPED:
            return {
                value: payload.key
            };
        case SET_ACTIVE:
            return {};
        default:
            return state;
    }
};

export default MainReducer;