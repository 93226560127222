import React, {Component} from 'react';
import './Styles/IMPACTMain.css';
import CookieRoutes from "./Stateless/CookieRoutes";
import ImpactRouter from "./Routing/ImpactRouter";

class IMPACTMain extends Component {

    constructor(props) {
        super(props);

        this.state = {
            reset: "resetCookies" in props,
        };
    }

    componentDidMount() {
        // let {cookie} = this.props;
        // if (this.state.reset) {
        //     let {key, path} = CookieRoutes.lastState;
        //     cookie.remove(key, path);
        // }
    }

    componentWillUnmount() {
        console.warn("componentWillUnmount");
    }

    async fetchSlideshowImgs(context = this) {
        // const imagesFetched = [];
        this.state.imgs.slideshow = [];
    }

    saveCookieOfState() {
        this.saveCookie(this.state, CookieRoutes.lastState);
    }

    saveCookie(data, cookieRoute) {
        this.state.cookie.set(cookieRoute.key, data, {path: cookieRoute.route});
    }

    render() {
        // function importAll(r) {
        //     return r.keys().map(r);
        // }
        // const images = importAll(require.context('../Assets/images/', false, /\.(png|jpe?g|svg)$/));
        // const svgs = importAll(require.context('../Assets/icons/', false, /\.(png|jpe?g|svg)$/));
        // console.warn("images", images);

        return (
            <div id={"homePage"}>
                <ImpactRouter/>
            </div>
        );
    }
}

export function toSet(arr) {
    return Array.from(new Set(arr))
}

export default IMPACTMain;
