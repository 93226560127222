import React from 'react';
import './Styles/BurgerMenuContainerMobile.css';
import NavigationItemMobile from "./NavigationItemMobile";
import articleLinks from "../../Routing/urls/ArticleLinks";

const BurgerMenuContainer = (props) => {
    // const {items,} = props;

    const getItem = () => {
        // if(!this.state.articleLinks) return [];
        let classNames = "nav-item";
        // let activeClassName = "active";
        let items = [];


        for (let i = 0; i < articleLinks.length; i++) {
            let item = articleLinks[i];

            item.classNames = classNames;
            item.context = this;
            items.push(item);
        }

        return items;
    };


    return (
        <div className={"mobile menu items wrapper"}>
            <ul>
                {
                    getItem().map(item => NavigationItemMobile(item, {key: item.index}))
                }
            </ul>
        </div>
    )
};

export default BurgerMenuContainer;
