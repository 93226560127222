import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import HomePageNavigation from "../HomePageNavigation";
import MainContent from "../MainContent";
import Header from "../Header";
import Footer from "../Footer";
import ImpactRouteEndpoints from "./ImpactRouteEndpoints";

class ImpactRouter extends Component {

    render() {
        return (
            <Router>
                <Switch>
                    {/*{this.getRoutes()}*/}

                    <Route path={"/"} exact render={() => {
                        return (
                            <div>
                                <Header/>
                                <HomePageNavigation/>
                                <MainContent/>
                                <Footer/>
                            </div>
                        )
                    }}/>

                    {
                        ImpactRouteEndpoints.homeNavigation
                    }

                </Switch>
            </Router>
        );
    }
}

export default ImpactRouter;
