import {ReactComponent as e_mail} from "./icons/e-mail.svg";
import {ReactComponent as facebook} from "./icons/facebook.svg";
import {ReactComponent as instagram} from "./icons/instagram.svg";
import {ReactComponent as menu} from "./icons/menu.svg";
import {ReactComponent as close} from "./icons/close.svg";

export const load = (fname, suffix = "json") => require(`./icons/${fname}.${suffix}`);
export const getImage = (id) => {
    return id in iconContainer ? updated(id, "src", iconContainer[id]) : null;
};
export const updated = (id, key, val) => {
    let _obj = iconJSONContainer[id];

    if (key in _obj || _obj[key]) {
        return _obj;
    }

    _obj[key] = iconContainer[id];
    iconJSONContainer.id = _obj;
    return _obj;
};

export const e_mailJSON = load("e-mail");
export const facebookJSON = load("facebook");
export const instagramJSON = load("instagram");
export const menuJSON = load("menu");
export const closeJSON = load("close");

export const iconContainer = {
    "mail": e_mail,
    facebook: facebook,
    instagram: instagram,
    menu: menu,
    close: close,
};

export const iconJSONContainer = {
    "mail": e_mailJSON,
    facebook: facebookJSON,
    instagram: instagramJSON,
    menu: menuJSON,
    close: closeJSON,
};

const Icons = {
    socialmedia: [
        getImage("mail"),
        getImage("facebook"),
        getImage("instagram"),
    ],
    navigation: {
        menu: getImage("menu"),
        close: getImage("close"),
    }
};

export default Icons;