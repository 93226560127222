import React from 'react';

const UnLoader = () => {
    return (
        <div>
            Alternative IMAGE
        </div>
    )
};

export default UnLoader;
