import React, {Component} from 'react';
import './Styles/ArticleTemplate.css';
import {Link} from "react-router-dom";
import {fetchData} from "./Stateless/HelperFunctions";
import {setArticles, setImages} from "../redux/reduxActions/MainActions";
import SVG from "./Stateless/SVGcomponent";
import Icons from "../Assets/Icons";
import {Gradient} from 'react-gradient';

const toggleBodyScroll = state => {
    let className = "noScroll";
    let func = state ? (name) => document.body.classList.add(name) : (name) => document.body.classList.remove(name);
    func(className);
};

class ArticleTemplate extends Component {

    printArticle = () => {
        if (this.props.activeArticle === {}) {
            return (<div className={"empty article"}/>)
        }
        else {
            return (
                <Gradient
                    className={"article wrapper"}
                    gradients={this.state.gradients} // required
                    // property="background"
                    duration={2000}
                    angle="45deg"
                    // transitionType="sequential"
                >
                    <div className={"article-close wrapper"}>
                        <Link to={"/"} onClick={() => {
                            toggleBodyScroll(false);
                        }}>
                            <div className={"icon wrapper"}>
                                <SVG {...Icons.navigation.close}/>
                            </div>
                        </Link>
                    </div>

                    <article className={"article"}>
                        <h4 className={"subject"}>{this.state.preTitle}</h4>
                        <h1 className={"title"}>{this.state.title}</h1>
                        <p>
                            {this.state.content}
                        </p>
                    </article>
                </Gradient>
            )
        }
    };

    constructor(props) {
        super(props);

        const {href, title, index, _content} = props.link;
        const preTitle = props.link["pre_title"];

        this.state = {
            index: index,
            title: title,
            content: _content,
            href: href,
            preTitle: preTitle,
            // classNames: classNames,
            gradients: [
                [
                    '#2d6dfc',
                    '#528dfc'
                ],
                // ['#ff2121', '#25c668'],
            ],
        };

        // toggleBodyScroll(this.state.activeArticle);
        toggleBodyScroll(props.visible);
    }

    fetchErrythang() {
        // if()

        fetchData("http://localhost:8000/api/v2/images/1")
            .then(json => {
                setImages(json);
            });


        fetchData("http://localhost:8000/api/v2/documents")
            .then(json => {
                setArticles(json.items, {});
            });
    }

    render() {
        return this.printArticle();
    }
}

export default ArticleTemplate;
