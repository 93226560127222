// import {combineReducers} from "redux";
import MainReducer from "./MainReducer";

// Combine Reducers
// const reducers = combineReducers({
//     main: MainReducer,
// });

const reducers = MainReducer;

export default reducers;