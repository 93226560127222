// ACTIONS
export const SET = "SET";
export const GET = "GET";
export const GET_MAPPED = "GET_MAPPED";
export const INIT = "INIT";
export const SET_ACTIVE = "ACTIVE";
export const CLOSE_ARTICLE = "CLOSE";

// SETTERS
export const setImages = ({images}) => {
    return setData("images", images)
};

export const setData = (key, data) => {
    return {
        type: SET,
        key: key,
        value: data,
    }
};

export const setArticles = ({articles, activeArticle}) => {
    return {
        type: SET,
        articles: articles,
        activeArticle: activeArticle
    }
};

export const initLinks = ({links, activeLink}) => {
    return {
        type: INIT,
        links: links,
        activeLink: activeLink,
    }
};

export const setArticleActive = ({id}) => {
    return {
        type: SET_ACTIVE,
        activeArticle: id,
        previousArticle: getPreviousOfArticle(id),
        nextArticle: getNextOfArticle(id),
    }
};

export const closeArticle = ({id}) => {
    return {
        type: CLOSE_ARTICLE,
        activeArticle: id,
    }
};

export const setRouter = ({router}) => {
    return {
        type: SET,
        key: "router",
        value: router,
    }
};

// GETTERS
export const getNextOfArticle = (id) => {
    // TODO: return next weeks article if available
    return null;
};

export const getPreviousOfArticle = (id) => {
    // TODO: return last weeks article if available
    return null;
};

export const getLinks = () => {
    return getFromKey("links")
};

export const getMapped = () => {
    return {
        type: GET_MAPPED,
    }
};

export const getData = () => {
    return {
        type: GET
    }
};

export const getFromKey = (key) => {
    return {
        type: GET,
        key: key,
    }
};

// MAPPING
export default function mapStateToProps(state) {
    return {
        ...state
    }
}

