import React, {} from 'react';
import articleLinks from "../../Routing/urls/ArticleLinks";
import {Route} from "react-router-dom";
import ArticleTemplate from "../ArticleTemplate";

const ImpactRouteEndpoints = {};

ImpactRouteEndpoints.homeNavigation = Array.from(articleLinks).map(url => {
    const {href, index} = url;
    return (
        <Route path={`/${href}/`} key={`link-${href}-${index}`}
               render={() => <ArticleTemplate link={{...url}} visible={false}/>}/>
    )
});

ImpactRouteEndpoints.footerNavigation = Array.from(articleLinks).map(url => {
    const {href, article, index} = url;

    return (
        <Route path={`/${href}/`} key={`link-${href}-${index}`}
               render={() => <ArticleTemplate link={{...article}} visible={false}/>}/>
    )
});


export default ImpactRouteEndpoints;
