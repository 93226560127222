// import Cookies from "universal-cookie";
// import Images from "./Assets/Images";

const Starter = () => {
    // // load cookies
    // let cookies = new Cookies();
    //
    // // load images
    // //// slideshow
    // let slideshowImgs = Images.slideshowImgs;

};

export default Starter;
