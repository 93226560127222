import React from 'react';
import ReactDOM from 'react-dom';
import './Styles/index.css';
import * as serviceWorker from './serviceWorker';
import IMPACTMain from "./Components/IMPACTMain";
import Provider from "./redux/Provider";
import Starter from "./OnStartup";

// TODO: load from cookies on mount
// TODO: THEN > asyncFetch

Starter();

const home = (<IMPACTMain resetCookies/>);

ReactDOM.render(
    Provider(home),
    document.getElementById('impact-app')
);

serviceWorker.unregister();
