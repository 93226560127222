import {objToArr, sortByIndex} from "../../Components/Stateless/HelperFunctions";

const articles = require("../json/Articles.json");

let articlesAsArray = objToArr(articles.articles);
let articleLinks = sortByIndex(articlesAsArray);

export const Routes = {
    articles: {
        vision: "vision",
        teens: "teens",
        geben: "geben",
        events: "events",
        kontakt: "kontakt",
    },
    footer: {
        social: {
            instagram: "",
            facebook: "",
            email: "",
        },
        navigation: {
            vision: "vision",
            teens: "teens",
            geben: "geben",
            events: "events",
            kontakt: "kontakt",
        },
        legal: {
            datenschutz: "datenschutz",
            impressum: "impressum",
        },
    },
    home: "/",
};

export default articleLinks;
