import React from "react";
import {Link} from "react-router-dom";
import "./Styles/NavigationItem.css";

const NavigationItem = prop => {
    const {context, link_title, title, classNames, index, href, pre_title} = prop;
    const t = pre_title[0] ? `${pre_title[1]}-` : "";
    return (
        <li key={`${index}:{${t}${title}}`} className={classNames}>
            <div className={"item wrapper long trans"}>
                <Link to={`/${href}`}>
                    <span onClick={() => {
                        context.toggleArticle(true);
                    }}>
                        <div className={"link wrapper trans"}>
                            {link_title}
                        </div>
                    </span>
                </Link>
            </div>
        </li>
    )
};

export default NavigationItem;