export const loadImages = (fname) => require(`./images/${fname}`);
export const getImage = (id) => id in imagesContainer ? imagesContainer[id] : null;

export const standard = loadImages("standard.png");
export const abstract = loadImages("joel-filipe-201191-unsplash.jpg");
export const prayer = loadImages("jack-sharp-579583-unsplash.jpg");
export const bibleFromTop = loadImages("aaron-burden-307060-unsplash.jpg");
export const shipOnSea = loadImages("adam-azim-580571-unsplash.jpg");
export const openBible = loadImages("aaron-burden-287555-unsplash.jpg");

export const imagesContainer = {
    standard: {
        id: "standard",
        img: standard,
        source: {
            href: "https://unsplash.com/photos/UIib0bAvWfs"
        }
    },
    abstract: {
        id: "abstract",
        img: abstract,
        source: {
            href: "https://unsplash.com/photos/UIib0bAvWfs"
        }
    },
    prayer: {
        id: "prayer",
        img: prayer,
        source: {
            href: "https://unsplash.com/photos/UIib0bAvWfs"
        }
    },
    bibleFromTop: {
        id: "bibleFromTop",
        img: bibleFromTop,
        source: {
            href: "https://unsplash.com/photos/UIib0bAvWfs"
        }
    },
    shipOnSea: {
        id: "shipOnSea",
        img: shipOnSea,
        source: {
            href: "https://unsplash.com/photos/UIib0bAvWfs"
        }
    },
    openBible: {
        id: "openBible",
        img: openBible,
        source: {
            href: "https://unsplash.com/photos/UIib0bAvWfs"
        }
    },
};

const Images = {
    slideshowImgs: [
        // getImage("standard"),
        getImage("abstract"),
        getImage("prayer"),
        getImage("bibleFromTop"),
        getImage("shipOnSea"),
        getImage("openBible"),
    ],
};

export default Images;