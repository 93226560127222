import React, {Component} from 'react';
import './Styles/Footer.css';
import Glyphs from "../asdf/Glyphs";
import FooterCol from "./Stateless/FooterCol";
import footerRoutes from "../Routing/urls/FooterRoutes";
import {objToArr} from "./Stateless/HelperFunctions";

class Footer extends Component {

    heart_glyph = (hover = false) => {
        let glyph = Glyphs.heart.inactive;
        if (hover) {
            glyph = Glyphs.heart.active;
        }
        this.setState({
            heartState: glyph
        })
    };

    constructor(props) {
        super(props);
        this.state = {
            heartState: Glyphs.heart.inactive,
        };
    }

    render() {
        return (
            <footer id="footer">
                <div className={"footer wrapper"}>
                    <div className={"coloumns wrapper"}>{
                        objToArr(footerRoutes).map((col, idx, arr) => {
                            let {links} = col;
                            col["links"] = objToArr(links);
                            return FooterCol(col);
                        })
                    }</div>

                    <div className={"last wrapper"}>
                        <address>
                            <span>IMPACT Church</span><br/>
                            <span>Salzschlirfer Str. 7 </span><br/>
                            <span>Frankfurt a. Main</span><br/>
                        </address>

                        <h6 className={"small"}
                            onMouseEnter={() => this.heart_glyph(true)}
                            onMouseLeave={() => this.heart_glyph(false)}>
                            <span>Made with</span>
                            <span className={this.state.heartState}/>
                            <span> in Frankfurt!</span>
                        </h6>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
